import React from "react";
import { XMarkIcon } from '@heroicons/react/24/outline';
import IDrawer from "@/interfaces/IDrawer";

const Drawer = ({ children, isOpen, setIsOpen }: IDrawer) => {
    return (
        <main
            className={
                "fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
                (isOpen
                    ? "transition-opacity opacity-100 duration-500 translate-x-0"
                    : "transition-all delay-500 opacity-0 -translate-x-full")
            }
        >
            <section
                className={
                    "w-80 max-w-sm left-0 h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform " +
                    (isOpen ? "translate-x-0" : "-translate-x-full")
                }
            >
                <article className="relative w-80 max-w-lg pb-10 flex flex-col space-y-6 h-full bg-gray-900">
                    <header className="px-4 py-4 flex items-center justify-between border-b border-gray-700">
                        <h2 className="text-lg font-medium text-gray-100">$LOS</h2>
                        <XMarkIcon className="block h-6 w-6 text-gray-100 cursor-pointer" onClick={() => setIsOpen(false)} />
                    </header>
                    <div className="px-4 py-4 flex-1 overflow-y-auto" onClick={() => setIsOpen(false)}>
                        {children}
                    </div>
                </article>
            </section>
            <section
                className="w-screen h-full cursor-pointer"
                onClick={() => setIsOpen(false)}
            ></section>
        </main>
    );
}

export default Drawer;