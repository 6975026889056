import React from "react";
import Link from "next/link";
import { IMenuItem } from "@/interfaces/IMenuItem";
import WalletConnect from "@/components/WalletConnect";

const navigation: IMenuItem[] = [
    { title: 'Home', href: '#home', current: false },
    { title: 'About', href: '#about', current: false },
    { title: 'Pre-sale', href: '#presale-section', current: false },
    { title: 'Roadmap', href: '#roadmap', current: false },
    { title: 'Tokenomics', href: '#tokenomics', current: false },
    { title: 'FAQ', href: '#faq-section', current: false },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

const DrawerData = ({ isConnected, address }: { isConnected: boolean, address: string | null | undefined }) => {
    return (
        <div className="space-y-1">
            {navigation.map((item) => (
                <Link
                    key={item.title}
                    href={item.href}
                    className={classNames(
                        item.current ? 'bg-gray-700 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-4 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                >
                    {item.title}
                </Link>
            ))}
            <div className="mt-4 px-4">
                <WalletConnect />
            </div>
        </div>
    );
}

export default DrawerData;