import { Icon } from "@chakra-ui/react";
import { FaEthereum } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { useDisconnect, useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";

export default function WalletConnect() {
    const { open, close } = useWeb3Modal()
    const { disconnect } = useDisconnect();
    const { chainId, isConnected, address } = useWeb3ModalAccount()

    return (
        <>
            {!isConnected ? (
                <button
                    onClick={() => open()}
                    className="w-full flex items-center justify-center text-xl font-semibold py-4 z-30 px-2 xl:px-6 text-white text-center lg:px-12  rounded-xl transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100 bg-gradient-to-r from-indigo-700 to-purple-600"
                >
                    <Icon as={FaEthereum} />
                    Connect wallet
                </button>
            ) : (
                <button
                    onClick={() => disconnect()}
                    className="w-full flex flex-col lg:flex-row items-center xl:gap-2 text-xl font-semibold py-4 z-30 px-2 xl:px-6 text-white text-center lg:px-12  rounded-xl transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100 bg-gradient-to-r from-indigo-700 to-purple-600"
                >
                    <span className="flex items-center gap-1">
                        <Icon as={BiLogOut} />
                        Disconnect
                    </span>
                    <span className="flex items-center">
                        {`${address?.slice(0, 4)}...${address?.slice(-4)}`}
                    </span>
                </button>
            )}
        </>
    );
}