import Footer from '@/components/Layout/Partials/Footer'
import Header from '@/components/Layout/Partials/Header'
import { ILayout } from '@/interfaces/ILayout'

const Layout = ({ children }: ILayout) => {
    return (
        <>
            <Header />

                {children}

            <Footer />
        </>
    )
}


export default Layout