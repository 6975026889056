'use client'

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '92c400cb63ed0b2afad1de68eecfa047'

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com',
}

const ganache = {
  chainId: 1337,
  name: 'Ganache',
  currency: 'ETH',
  explorerUrl: '',
  rpcUrl: 'http://127.0.0.1:7545'
}

const sepolia = {
  chainId: 11155111,
  name: 'Sepolia',
  currency: 'ETH',
  explorerUrl: 'https://sepolia.etherscan.io/',
  rpcUrl: 'https://ethereum-sepolia-rpc.publicnode.com'
}

// 3. Create a metadata object
const metadata = {
  name: 'Car$LOS',
  description: '$LOS',
  url: 'http://carloscrypto.com', // origin must match your domain & subdomain
  icons: ['https://carloscrypto.com/assets/images/carlos/los-logo-new.png']
}
// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1 // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet, ganache, sepolia],
  projectId,
  enableAnalytics: false, // Optional - defaults to your Cloud configuration
  enableOnramp: false // Optional - false as default
})

export function Web3Modal({ children }) {
  return children
}