"use client"
import type { Metadata, ResolvingMetadata } from "next"
import { useEffect } from "react"
import { appConfig } from '@/config/app'
import { Inter } from "next/font/google"
import { ThemeProvider } from "next-themes";
import AOS from "aos"

import "aos/dist/aos.css"
import "@/styles/globals.css"
import { ContextWrapper } from "@/context/ContextWrapper"
import { Web3Modal } from "@/context/Web3Modal"
import Layout from "@/components/Layout/Layout"

const inter = Inter({ subsets: ["latin"] })
const isProduction = appConfig.env === 'production';
/* 
export async function generateMetadata(
  parent: ResolvingMetadata
): Promise<Metadata> {

  return {
    metadataBase: new URL(appConfig.url),
    title: {
      default: appConfig.name,
      template: `%s | ${appConfig.name}`,
    },
    description: appConfig.description,
    robots: { index: true, follow: true },
    // !STARTERCONF this is the default favicon, you can generate your own from https://realfavicongenerator.net/
    // ! copy to /favicon folder
    icons: {
      icon: '/favicon/favicon.ico',
      shortcut: '/favicon/favicon-16x16.png',
      apple: '/favicon/apple-touch-icon.png',
    },
    manifest: `/favicon/site.webmanifest`,
    openGraph: {
      url: appConfig.url,
      title: appConfig.name,
      description: appConfig.description,
      siteName: appConfig.name,
      images: [`${appConfig.url}/images/og.jpg`],
      type: 'website',
      locale: 'en_US',
    },
    twitter: {
      card: 'summary_large_image',
      title: appConfig.name,
      description: appConfig.description,
      images: [`${appConfig.url}/images/og.jpg`],
    },
  }
} */

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {

  useEffect(() => {
    AOS.init({
      once: true
    })
  }, [])

  return (
    //<ContextWrapper>
    <html lang="en" suppressHydrationWarning>
      <body className={inter.className}>
        <ThemeProvider attribute="class">
          <Web3Modal>
            <Layout>
              {children}
            </Layout>
          </Web3Modal>
        </ThemeProvider>
      </body>
    </html>
    //</ContextWrapper>
  )
}
