import { useCallback, useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { IHeader } from '@/interfaces/IHeader';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { IMenuItem } from '@/interfaces/IMenuItem';
import Image from 'next/image';
import BuyLos from '@/components/BuyLos';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import Drawer from '@/components/Layout/Partials/Drawer';
import DrawerData from '@/components/Layout/Partials/DrawerData';
import WalletConnect from '@/components/WalletConnect';

const MenuItem = dynamic(() => import('@/components/Layout/Nav/MenuItem'));

const navigation: IMenuItem[] = [
    { title: 'Home', href: '#home', current: false },
    { title: 'About', href: '#about', current: false },
    // { title: 'Where to buy', href: '#buy-section', current: false },    
    { title: 'Pre-sale', href: '#presale-section', current: false },
    { title: 'Roadmap', href: '#roadmap', current: false },
    { title: 'Tokenomics', href: '#tokenomics', current: false },
    { title: 'FAQ', href: '#faq-section', current: false },
];

const Header = ({ }: IHeader) => {
    const { chainId, isConnected, address } = useWeb3ModalAccount();
    const [navBg, setNavBg] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const changeNavBg = () => {
        window.scrollY >= 10 ? setNavBg(true) : setNavBg(false);
    }

    useEffect(() => {
        changeNavBg();
        window.addEventListener('scroll', changeNavBg);
        return () => {
            window.removeEventListener('scroll', changeNavBg);
        }
    }, []);

    const classNames = function (...classes: string[]) {
        return classes.filter(Boolean).join(' ');
    }

    return (
        <Disclosure as="nav" className={`fixed w-full z-50 ${navBg ? 'scrolled-nav' : ''}`}>
            <div className="mx-auto max-w-7xl p-3 md:p-4 lg:px-8">
                <div className="relative flex h-12 sm:h-20 items-center">
                    <div className="flex flex-1 items-center sm:justify-between">

                        {/* LOGO */}

                        <div className="flex flex-shrink-0 items-center">
                            <Image
                                className="block h-10 w-20px lg:hidden"
                                src={'/assets/images/carlos/los-logo-new.png'}
                                alt="LOS-logo"
                                width={150}
                                height={75}
                            />
                            <Image
                                className="hidden h-48px w-48px lg:block"
                                src={'/assets/images/carlos/los-logo-new.png'}
                                alt="LOS-logo"
                                width={150}
                                height={75}
                            />
                        </div>

                        {/* LINKS */}

                        <div className="hidden lg:flex items-center border-right ">
                            <div className="flex justify-end xl:space-x-4">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.title}
                                        href={item.href}
                                        className={classNames(
                                            item.current ? 'bg-gray-900' : 'navlinks text-white hover:text-offwhite hover-underline',
                                            'px-3 py-4 rounded-md text-lg font-normal'
                                        )}
                                        aria-current={item.href ? 'page' : undefined}
                                    >
                                        {item.title}
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <div className='hidden lg:flex items-center'>
                            <WalletConnect />
                        </div>
                    </div>

                    {/* DRAWER FOR MOBILE VIEW */}

                    {/* DRAWER ICON */}

                    <div className='block lg:hidden'>
                        <Bars3Icon className="block h-6 w-6 text-white" aria-hidden="true" onClick={() => setIsOpen(true)} />
                    </div>

                    {/* DRAWER LINKS DATA */}

                    <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
                        <DrawerData isConnected={isConnected} address={address} />
                    </Drawer>
                </div>
            </div>
        </Disclosure>
    );
}

export default Header;