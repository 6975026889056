import Image from "next/image";
import Link from "next/link";
import X from "@/components/X";
import Telegram from "@/components/Telegram";

export default function Footer() {
  return (
    <div className=" relative">
      <div className="radial-bg hidden lg:block"></div>
      <div className="mx-auto max-w-2xl mt-64 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-24 grid grid-cols-1 gap-y-10 gap-x-16 sm:grid-cols-2 lg:grid-cols-12 xl:gap-x-8">
          <div className="col-span-6">
            <Image
              className="block h-14 w-20px mb-4"
              src={"/assets/images/carlos/los-logo-new.png"}
              alt="LOS-logo"
              width={150}
              height={75}
            />
            <h3 className="text-lightblue text-sm font-normal leading-9 mb-4 lg:mb-16">
              {" "}
            </h3>
            <div className="flex gap-4">
              <X />
              <Telegram />
            </div>
          </div>

          <div className="group relative col-span-2">
            <p className="text-white text-xl font-medium mb-9">Sitemap</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <Link
                href="#home"
                className="text-offwhite text-sm font-normal mb-6 space-links "
              >
                Home
              </Link>
              <Link
                href="#about"
                className="text-offwhite text-sm font-normal mb-6 space-links"
              >
                About
              </Link>
              <Link
                href="#presale-section"
                className="text-offwhite text-sm font-normal mb-6 space-links"
              >
                Pre-sale
              </Link>
              <Link
                href="#roadmap"
                className="text-offwhite text-sm font-normal mb-6 space-links"
              >
                Roadmap
              </Link>
              <Link
                href="#tokenomics"
                className="text-offwhite text-sm font-normal mb-6 space-links"
              >
                Tokenomics
              </Link>
              <Link
                href="#faq-section"
                className="text-offwhite text-sm font-normal mb-6 space-links"
              >
                FAQ
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="py-8 px-4 border-t border-t-lightblue">
        <h3 className="text-center text-offwhite">
          @{new Date().getFullYear()} - All Rights Reserved - Carlos $LOS
        </h3>
      </div>
    </div>
  );
}
